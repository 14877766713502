import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Checkbox } from "@atlaskit/checkbox";
import capitalizeFirstLetter from "../capitalizeFirstLetter";
import "@zendeskgarden/css-buttons";

import firebase from "../firebaseInit";
import {
  restaurantID,
  isDeliveryRestaurant,
  isReservationRestaurant,
} from "../restaurantInfo";

import "firebase/firestore";
import "firebase/auth";

const format = require("date-fns/format");

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2.22,
    paddingBottom: theme.spacing.unit * 2.22,
  },
  card: {
    width: 555,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 2,
  },
});

const today = new Date();
const year = format(today, "YYYY");
const month = format(today, "MMMM");
const day = format(today, "Do");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      numPickupOrders: 0,
      numDeliveryOrders: 0,
      numReservations: 0,
      signedIn: null,
    };
  }

  componentDidMount() {
    const firestore = firebase.app().firestore();
    this.restaurantRef = firestore.collection("restaurants").doc(restaurantID);
    this.todaysOrdersRef = this.restaurantRef
      .collection("orders")
      .doc(year)
      .collection(month)
      .doc(day)
      .collection("todays_orders");
    this.checkIfSignedIn();
  }

  keepOrderMonitorUpdated = () => {
    this.todaysOrdersRef.orderBy("timestamp").onSnapshot(snapshot => {
      const { orders } = this.state;
      let ordersCopy = [...orders];
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          const newOrder = change.doc.data();
          const orderID = change.doc.id;
          newOrder.id = orderID;
          ordersCopy.unshift(newOrder);
        } else if (change.type === "modified") {
          const orderIndex = ordersCopy.findIndex(
            order => order.id === change.doc.id
          );
          const newOrder = change.doc.data();
          const orderID = change.doc.id;
          newOrder.id = orderID;
          ordersCopy[orderIndex] = newOrder;
        } else {
          ordersCopy = ordersCopy.filter(order => order.id !== change.doc.id);
        }
      });
      this.refreshOrderCounter(ordersCopy);
      this.setState(
        {
          orders: ordersCopy,
        },
        () => this.updatePrinted(ordersCopy)
      );
    });
  };

  refreshOrderCounter = orders => {
    let numPickupOrders = 0;
    let numDeliveryOrders = 0;
    let numReservations = 0;
    orders.forEach(order => {
      if (order.orderType === "pickup") {
        numPickupOrders += 1;
      } else if (order.orderType === "delivery") {
        numDeliveryOrders += 1;
      } else {
        numReservations += 1;
      }
    });
    this.setState({
      numPickupOrders,
      numDeliveryOrders,
      numReservations,
    });
  };

  updatePrinted = ordersArray => {
    for (let i = 0, len = ordersArray.length; i < len; i += 1) {
      if (!ordersArray[i].printed) {
        this.todaysOrdersRef
          .doc(ordersArray[i].id)
          .update({ printed: true })
          .then(() => window.open(ordersArray[i].receipt));
      }
    }
  };

  markAsRead = e => {
    this.todaysOrdersRef.doc(e.target.value).update({
      read: true,
    });
  };

  checkIfSignedIn = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState(
          {
            signedIn: true,
          },
          this.keepOrderMonitorUpdated
        );
      } else {
        this.setState({
          signedIn: false,
        });
      }
    });
  };

  updateField = name => event => {
    const { value } = event.target;
    this.setState({ [name]: value.replace(/\s/g, "") });
  };

  signIn = () => {
    const { password, email, isChecked } = this.state;
    if (isChecked === true) {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() =>
          firebase.auth().signInWithEmailAndPassword(email, password)
        );
    } else {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() =>
          firebase.auth().signInWithEmailAndPassword(email, password)
        );
    }
  };

  handleCheck = () => {
    const { isChecked } = this.state;
    const newCheckedState = !isChecked;
    this.setState({
      isChecked: newCheckedState,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      orders,
      email,
      password,
      isChecked,
      numPickupOrders,
      numDeliveryOrders,
      numReservations,
      signedIn,
    } = this.state;
    let hours = today.getHours();
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    return (
      <div>
        {signedIn === false && (
          <div>
            <Paper
              className={classes.root}
              style={{ display: "table", margin: "0 auto", maxWidth: "88%" }}
            >
              <h2>Sign in to your FoodWeb account</h2>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Email"
                style={{ marginBottom: "0.5em" }}
                value={email}
                onChange={this.updateField("email")}
              />
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Password"
                type="password"
                value={password}
                onChange={this.updateField("password")}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Checkbox
                  isChecked={isChecked}
                  onChange={this.handleCheck}
                  value="Stay signed in"
                  label="Stay signed in"
                  name="checkbox-basic"
                  style={{ transform: "translateX(-3.33%)" }}
                />
              </div>
              <br />
              <br />
              <button
                className="c-btn c-btn--primary c-btn--full c-btn--danger"
                style={{ fontSize: "0.88em", fontWeight: 500 }}
                type="button"
                onClick={this.signIn}
              >
                Sign In
              </button>
            </Paper>
          </div>
        )}
        {signedIn === true && (
          <div>
            {orders.length > 0 ? (
              orders.map(item => (
                <Card
                  raised
                  className={classes.card}
                  style={{
                    margin: "auto",
                    backgroundColor: item.read ? "yellow" : "white",
                    marginTop: -8,
                    marginBottom: -8,
                    textAlign: "left",
                  }}
                  key={item.fullDate}
                >
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      style={{ color: "black" }}
                    >
                      {capitalizeFirstLetter(item.orderType)} Order for{" "}
                      <b>${item.total}</b> at{" "}
                      <b>
                        {item.timeOrderFor === "As Soon As Possible"
                          ? item.timeOrderFor
                          : item.customTime}
                      </b>
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "black" }}>
                      Customer: <b>{item.name}</b>
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "black" }}>
                      Order Received at {item.fullDate}
                    </Typography>
                    <Typography variant="subtitle1" style={{ color: "black" }}>
                      <a href={item.receipt}>Print</a>
                      &emsp;&emsp;
                      <button onClick={this.markAsRead} value={item.id}>
                        Mark as Read
                      </button>
                    </Typography>
                  </CardContent>
                  <Divider />
                </Card>
              ))
            ) : (
              <div>
                <Typography
                  variant="h1"
                  style={{ textAlign: "center", color: "black" }}
                >
                  Nothing right now, boss.
                </Typography>
                <br />
                <br />
                <Typography
                  variant="h3"
                  style={{ textAlign: "center", color: "black" }}
                  onClick={this.forceUpdate}
                >
                  <u>Check?</u>
                </Typography>
                <br />
                <br />
                <Typography
                  variant="h4"
                  style={{ textAlign: "center", color: "black" }}
                >
                  Last checked: {hours}:{minutes}:{seconds}
                </Typography>
              </div>
            )}
            <div style={{ position: "fixed", left: 0, bottom: 20 }}>
              <h2>Pickups Today: {numPickupOrders}</h2>
              {isDeliveryRestaurant && (
                <h2>Deliveries Today: {numDeliveryOrders}</h2>
              )}
              {isReservationRestaurant && (
                <h2>Reservations Today: {numReservations}</h2>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(App);
